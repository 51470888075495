import React from 'react';
import { Link } from 'gatsby';

import MenuMain from './MenuMain';
import SocialPagesFooter from './SocialPagesFooter';

const Footer = () => (
  <footer className="row full-width">
    <div className="large-6 columns">
      <MenuMain />
      <SocialPagesFooter />
    </div>
    <div className="large-6 medium-12 columns">
      <div className="footer-message">
        <p>
          Copyright © 2009-{new Date().getFullYear()} JLP Productions, Inc. All Rights Reserved.
        </p>
        <p>
          Designed and built by&nbsp;
          <a
            href="https://othermachines.com"
            target="_blank">
              Othermachines
          </a>
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
