import React from 'react';
import { Link } from 'gatsby';

import { slide as Menu } from 'react-burger-menu';
import SocialPages from './SocialPages';

class ReactBurgerMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }

  closeMenu() {
    this.setState({ menuOpen: false })
  }

  render() {
    const { menuOpen } = this.state;
    return (
      <Menu
        isOpen={menuOpen}
        width={250}
      >
        <Link to="/news" className="menu-item">News & Updates</Link>
        <Link to="/concert-dates" className="menu-item">Concerts</Link>
        <Link to="/recordings" className="menu-item">Recordings</Link>
        <Link to="/videos" className="menu-item">Videos</Link>
        <Link to="/biography" className="menu-item">Biography</Link>
        <Link to="/equipment" className="menu-item">Equipment</Link>
        <Link to="/messages" className="menu-item">Fan Board</Link>
        <Link to="/contact" className="menu-item">Contact</Link>
        <SocialPages />
      </Menu>
    );
  }
}

export default ReactBurgerMenu;
