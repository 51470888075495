import React from 'react';
import { Link } from 'gatsby';

const MenuMain = () => (
  <nav className="main-menu">
    <ul>
      <li
      >
        <Link to="/news">News & Updates</Link>
      </li>
      <li
      >
        <Link to="/concert-dates">Concerts</Link>
      </li>
      <li
      >
        <Link to="/recordings">Recordings</Link>
      </li>
      <li
      >
        <Link to="/videos">Videos</Link>
      </li>
      <li
      >
        <Link to="/biography">Biography</Link>
      </li>
      <li
      >
        <Link to="/equipment">Equipment</Link>
      </li>
    </ul>
  </nav>
);

export default MenuMain;
