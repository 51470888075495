import React from 'react';
import { Link } from 'gatsby';

const MenuTop = () => (
  <nav className="top-menu show-for-large-up">
    <ul>
      <li><Link to="/">Home</Link></li>
      <li><Link to="/messages">Fan Board</Link></li>
      <li><Link to="/contact">Contact/Bookings</Link></li>
    </ul>
  </nav>
);

export default MenuTop;
