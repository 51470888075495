/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import * as React from 'react';
import { PreviewStoreProvider } from 'gatsby-source-prismic';

export const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider>{element}</PreviewStoreProvider>
);

// To take action when your service worker finds an update, you can use the
// onServiceWorkerUpdateReady browser API. The following code will refresh the
// page when an update is found.
// See docs: https://github.com/gatsbyjs/gatsby/blob/master/docs/docs/add-offline-support-with-a-service-worker.md
export const onServiceWorkerUpdateReady = () => window.location.reload(true);
