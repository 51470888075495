import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        pic01: file(relativePath: { eq: "sm-fb-bw.png" }) {
          childImageSharp {
            fluid(maxWidth: 36, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          },
        },
        pic02: file(relativePath: { eq: "sm-yt-bw.png" }) {
          childImageSharp {
            fluid(maxWidth: 36, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          },
        },
      }
    `}
    render={data => (
      <div className="social-pages">
        <a
          href="https://www.facebook.com/jeanlucponty33"
          target="_blank"
          rel="me nofollow noreferrer"
        >
          <Image
            fluid={data.pic01.childImageSharp.fluid}
            alt="Facebook"
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UCosHpDl_JsJRhzIhv79ItZg?view_as=subscriber"
          target="_blank"
          rel="me nofollow noreferrer"
        >
          <Image
            fluid={data.pic02.childImageSharp.fluid}
            alt="YouTube"
          />
        </a>
      </div>
    )}
  />
);
