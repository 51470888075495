import React from 'react';
import PropTypes from 'prop-types';

import ReactBurgerMenu from './ReactBurgerMenu';
import Header from './Header';
import Footer from './Footer';

import '../assets/sass/main.scss';

// Animate On Scroll (AOS) library
import AOS from 'aos';
import 'aos/dist/aos.css';

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 'is-loading',
    };
  }

  componentDidMount() {
    const AOS = require('aos');
    this.aos = AOS;
    this.aos.init();

    this.timeoutId = setTimeout(() => {
      this.setState({ loading: '' });
    }, 100);
  }

  componentDidUpdate() {
    this.aos.refresh();
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children, location } = this.props;
    const { loading } = this.state;
    return (
      <div
        className={`layout ${loading}`}
      >
        <div className="hide-for-large-up">
          <ReactBurgerMenu />
        </div>
        <Header />
        <div className="off-canvas-wrap" data-offcanvas>
          <div className="inner-wrap">
            <div id="content">
                {/* Show banner on homepage only. Wrapping element needs to be
                    a <span>, otherwise it loses 'full-width cover-1' classes when
                    rendered offline; due to (I believe)
                    https://github.com/gatsbyjs/gatsby/issues/6059 */}
                {location.pathname === '/' && (
                  <span className="row full-width cover-1">
                    <div className="columns large-12">
                      &nbsp;
                    </div>
                  </span>
                )}
                <div className="row">
                  <div
                    className="small-12 columns"
                  >
                    {children}
                  </div>
                </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default Layout;
