import React from 'react';
import { Link } from 'gatsby';
import config from '../utils/config';

import MenuTop from './MenuTop';
import MenuMain from './MenuMain';

const Header = () => (
  <header>
    <div className="row full-width">
      <div className="small-12 columns">
        <MenuTop />
      </div>
    </div>
    <div className="row full-width">
      <div className="small-12 columns">
        <div className="logo">
          <div className="logo-alt-text">
            <Link
              to="/"
              className="animate-logo"
            >
              {config.siteTitle}
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="row full-width show-for-large-up">
      <div className="small-12 columns">
        <MenuMain />
      </div>
    </div>
  </header>
);

export default Header;
